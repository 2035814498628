import * as React from "react"
import {graphql} from "gatsby";
import {I18nContext, useTranslation} from 'gatsby-plugin-react-i18next';

import FullWidthContainer from "../components/grid/FullWidthContainer";
import ColoredBox from "../components/molecules/ColoredTextBox/ColoredBox";
import OrderForm from "../components/molecules/OrderForm/OrderForm";
import Text from "../components/atoms/Text/Text";
import List from "../components/molecules/List/List";
import PageReferencesTitle from "../components/atoms/PageReferencesTitle/PageReferencesTitle";
import PageReferencesText from "../components/atoms/PageReferencesText/PageReferencesText";
import StageTeaser from "../components/molecules/StageTeaser/StageTeaser";
import SmallTextCarousel, {SlideType} from "../components/molecules/SmallTextCarousel/SmallTextCarousel";
import Image from "../components/atoms/Image/Image";
import {GatsbyImage, getImage} from "gatsby-plugin-image";
import ReactPlayer from "react-player";
import Cookies from "js-cookie";
import {Redirect} from "@reach/router";

import GridContainer from "../components/grid/GridContainer";
import HalfNineFullCell from "../components/grid/HalfNineFullCell";
import InternalPathService from "../services/InternalPathService";
import Seo from "../components/global/Seo/Seo";

type propType = {
	data: any
	classes: any,
	context: any
}

const OrderPage = ({data}: propType) => {
	const SUPPORTED_LOGIN_TYPES = ['doccheck','swiss-rx'];
	const loginPath = "/login";
	const dbsKitVideoUrl = "/assets/videos/17611_takeda_CH_DBSkit_erklaervideo_la09.mp4";

	const {t} = useTranslation();
	const context = React.useContext(I18nContext);
	const currentLanguage = context.i18n.language;

	if (InternalPathService.isBrowserMode()) {
		const loginType = Cookies.get('login_type');
		if (loginType === undefined || !SUPPORTED_LOGIN_TYPES.includes(loginType)) {
			return <Redirect noThrow to={loginPath} />
		}
	}

	return (
		<>
			<Seo title={t('order:seo.title')} description={t('order:seo.description')} />
			<FullWidthContainer>
				<StageTeaser image={data.stageTeaser} imageAlt={t('order:imageAlt')}/>
			</FullWidthContainer>

			<ColoredBox>
				<FullWidthContainer>
					<h2>
						<Text text="order:orderForm.headline" />
					</h2>
					<p>
						<Text text="order:orderForm.copy" />
					</p>
				</FullWidthContainer>
				<OrderForm />
			</ColoredBox>

			<FullWidthContainer>
				<h1>{t('order:mainHeadline')}</h1>
				<h2>{t('order:sectionIntroduction.headline')}</h2>
				<p>
					<Text text={t('order:sectionIntroduction.copy1')}/>
				</p>
				<h3>{t('order:sectionIntroduction.subsectionSimpleDiagnosis.headline')}</h3>
				<p>
					<Text text={t('order:sectionIntroduction.subsectionSimpleDiagnosis.copy1')}/>
				</p>
			</FullWidthContainer>
			<FullWidthContainer className="margin-top-2">
				<Image className="hide-on-small" imagePath={"/assets/images/order/" + currentLanguage + "/diagnosis.svg"} imageAlt={t("order:sectionIntroduction.imageAltText")} />
				<SmallTextCarousel slideType={SlideType.CARD} className="hide-on-medium-up" slides={t("order:sectionIntroduction.slider", {returnObjects: true})} />
			</FullWidthContainer>

			<ColoredBox>
				<FullWidthContainer>
					<h2 className="major-headline">{t('order:sectionVideo.headline')}</h2>
					<h3 className="uppercase">{t('order:sectionVideo.copy1')}</h3>
				</FullWidthContainer>
				<GridContainer className="margin-top-large margin-bottom-4">
					<HalfNineFullCell expandForMobile={true}>
						<div title={t('order:sectionVideo.videoAltText')}>
							<ReactPlayer
								url={dbsKitVideoUrl}
								width="100%"
								height="100%"
								controls={true}
							/>
						</div>
					</HalfNineFullCell>
				</GridContainer>
				<FullWidthContainer>
					<GatsbyImage
						image={getImage(data.centoCard)!}
						loading="lazy"
						alt={t("order:sectionVideo.imageAltText")}
					/>
				</FullWidthContainer>
			</ColoredBox>

			<FullWidthContainer>
				<h2 className="major-headline">{t('order:sectionBloodDrop.headline')}</h2>
				<p className="subline">{t('order:sectionBloodDrop.copy1')} </p>
				<h3 className="headline margin-top-2">{t('order:sectionBloodDrop.subsectionWer.headline')}</h3>
				<p>
					<Text text={t('order:sectionBloodDrop.subsectionWer.copy1')}/>
				</p>
				<h3 className="headline">{t('order:sectionBloodDrop.subsectionWie.headline')}</h3>
				<p>
					<Text text={t('order:sectionBloodDrop.subsectionWie.copy1')}/>
				</p>
				<h3 className="headline">{t('order:sectionBloodDrop.subsectionAnWen.headline')}</h3>
				<p>
					<Text text={t('order:sectionBloodDrop.subsectionAnWen.copy1')}/>
				</p>
				<p>
					<Text text={t('order:sectionBloodDrop.subsectionAnWen.copy2')}/>
				</p>
			</FullWidthContainer>

			<FullWidthContainer>
				<h2>{t('order:sectionResults.headline')}</h2>
				<List list={t('order:sectionResults.list', {returnObjects: true})} />
			</FullWidthContainer>

			<FullWidthContainer>
				<PageReferencesTitle>
					<Text text="order:sectionReferences.headline" />
				</PageReferencesTitle>
				<PageReferencesText>
					<Text text="order:sectionReferences.copy" />
				</PageReferencesText>
				<PageReferencesText>
					<Text text="order:sectionReferences.explanation" />
				</PageReferencesText>
				<PageReferencesText>
					<Text text="approvalCode" />
				</PageReferencesText>
			</FullWidthContainer>
		</>
	)
}

export default OrderPage

export const query = graphql`
	query($language: String!) {
		locales: allLocale(filter: {language: {eq: $language}, ns: {in: ["translation", "order"]}}) {
			edges {
				node {
					ns
					data
					language
				}
			}
		},
		stageTeaser: file(
			relativePath: { eq: "order/StageTeaserDesktop.jpg"}
		) {
			id
			relativePath
			childImageSharp {
				gatsbyImageData(
					placeholder: BLURRED,
					formats: [AUTO, WEBP, AVIF],
					quality: 75,
					sizes: "(min-width: 1300px) 1292px, (min-width: 640px) 100vw,  200vw"

				)
			}
		},
		centoCard: file(
			relativePath: { eq: "order/cento-card-anleitung.png"}
		) {
			id
			relativePath
			childImageSharp {
				gatsbyImageData(
					placeholder: BLURRED,
					formats: [AUTO, WEBP, AVIF],
					quality: 75,
				)
			}
		},
	}
`;
