import React, {ChangeEventHandler} from "react";

import * as styles from "./CheckboxField.module.scss";
import classNames from "classnames";

type propsType = {
  children: React.ReactNode
  value: boolean
  name: string
  onChange: ChangeEventHandler
  required?: boolean
  hasError: boolean
}

const CheckboxField = (props: propsType) => {

  const required = props.required ? props.required : false;

  const [value, setValue] = React.useState(props.value);

  const formField = classNames(
      styles.checkbox,
      {[styles.error]: props.hasError}
  );

  React.useEffect(() => {
    setValue(props.value);
  }, [props.value])

  return (
    <div className={styles.root} key={props.name}>
      <input id={props.name} className={formField} type="checkbox" checked={value} onChange={props.onChange} name={props.name} required={required} />
      <label htmlFor="{props.name}">
        <span>{props.children}</span>
      </label>
    </div>
  );
};

export default CheckboxField;