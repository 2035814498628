import React, {ChangeEventHandler} from "react";

import * as styles from "./InputField.module.scss";
import classNames from "classnames";

type propsType = {
  placeholder: string
  errorMessage: string
  value: string
  name: string
  onChange: ChangeEventHandler
  hasError: boolean
  required?: boolean
  type?: string
}

const InputField = (props: propsType) => {

  const fieldType = props.type ? props.type : 'text';
  const required = props.required ? props.required : false;

  const formField = classNames(
    styles.formField,
    {[styles.error]: props.hasError}
  );

  return (
    <div>
      <input
        className={formField}
        placeholder={props.placeholder}
        value={props.value}
        onChange={props.onChange}
        type={fieldType}
        required={required}
        name={props.name}
      />
      <span className={styles.errorMessage}>{props.errorMessage}</span>
    </div>
  );
};

export default InputField;