import React, {BaseSyntheticEvent, useState} from "react";

import * as styles from "./OrderForm.module.scss";
import InputField from "../InputField/InputField";
import FullWidthContainer from "../../grid/FullWidthContainer";
import GridContainer from "../../grid/GridContainer";
import HalfWidthCell from "../../grid/HalfWidthCell";
import {useTranslation} from 'gatsby-plugin-react-i18next';
import Text from "../../atoms/Text/Text";
import CheckboxField from "../CheckboxField/CheckboxField";
import Button from "../../atoms/Button/Button";
import classNames from "classnames";

const OrderForm = () => {
  const {t} = useTranslation();

  enum FieldNames {
    CLINIC = "praxis",
    TITLE = "title",
    MAIL = "email",
    FIRST_NAME = "firstname",
    LAST_NAME = "lastname",
    STREET = "street",
    STREET_NUMBER = "house_nr",
    ZIP_CODE = "postcode",
    CITY = "city",
    AMOUNT = "count",
    PRIVACY_POLICY_ACCEPTED = "privacyPolicyAccepted"
  }

  const [formData, setFormData] = useState({
    [FieldNames.CLINIC]: "",
    [FieldNames.TITLE]: "",
    [FieldNames.MAIL]: "",
    [FieldNames.FIRST_NAME]: "",
    [FieldNames.LAST_NAME]: "",
    [FieldNames.STREET]: "",
    [FieldNames.STREET_NUMBER]: "",
    [FieldNames.ZIP_CODE]: "",
    [FieldNames.CITY]: "",
    [FieldNames.AMOUNT]: "",
    [FieldNames.PRIVACY_POLICY_ACCEPTED]: false,
  });

  const getInitialErrorState = () => ({
    [FieldNames.CLINIC]: false,
    [FieldNames.TITLE]: false,
    [FieldNames.MAIL]: false,
    [FieldNames.FIRST_NAME]: false,
    [FieldNames.LAST_NAME]: false,
    [FieldNames.STREET]: false,
    [FieldNames.STREET_NUMBER]: false,
    [FieldNames.ZIP_CODE]: false,
    [FieldNames.CITY]: false,
    [FieldNames.AMOUNT]: false,
    [FieldNames.PRIVACY_POLICY_ACCEPTED]: false,
  })

  const [violationFields, setViolationFields] = useState(getInitialErrorState());
  const [formHasErrors, setFormHasErrors] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);

  const updateFormData = (e: BaseSyntheticEvent) => {
    const targetName = e.target.name;
    let value = e.target.value;

    if (FieldNames.PRIVACY_POLICY_ACCEPTED === targetName) {
      value = e.target.checked;
    }
    setFormData(values => ({...values, [targetName]: value}));
  };

  const submitForm = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();

    let requiredFieldsValid = true;
    const violations = getInitialErrorState();

    for (const [key, value] of Object.entries(formData)) {
      if (!value) {
        requiredFieldsValid = false;
        violations[key as FieldNames] = true;
      } else {
        violations[key as FieldNames] = false;
      }
    }

    setViolationFields(violations);
    setFormHasErrors(!requiredFieldsValid);

    if (requiredFieldsValid) {
      const body = JSON.stringify(formData);
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: body,
      }

      fetch('/order-kit.php', requestOptions)
        .then(() => setFormSubmitted(true))
        .catch(error => console.log(error));
    }
  }

  const orderClasses = classNames(
      styles.root,
      {[styles.submitted]: formSubmitted}
  );

  return (
    <div className={orderClasses}>
      {formSubmitted ? (
          <GridContainer>
            <HalfWidthCell expandForMobile>
              <div className={styles.successMessage}>
                <h4 className={styles.successHeadline}>
                  <Text text="order:orderForm.successHeadline" />
                </h4>
                <p>
                  <Text text="order:orderForm.successMessage" />
                </p>
              </div>
            </HalfWidthCell>
          </GridContainer>
      ) : (
          <>
            <FullWidthContainer>
              <InputField placeholder={t('order:orderForm.placeholderClinic')} errorMessage="" value={formData[FieldNames.CLINIC]} onChange={updateFormData} hasError={violationFields[FieldNames.CLINIC]} name={FieldNames.CLINIC} />
            </FullWidthContainer>
            <GridContainer>
              <HalfWidthCell expandForMobile={true}>
                <InputField placeholder={t('order:orderForm.placeholderTitle')} errorMessage="" value={formData[FieldNames.TITLE]} onChange={updateFormData} hasError={violationFields[FieldNames.TITLE]} name={FieldNames.TITLE} />
              </HalfWidthCell>
              <HalfWidthCell expandForMobile={true}>
                <InputField placeholder={t('order:orderForm.placeholderMail')} errorMessage="" value={formData[FieldNames.MAIL]} onChange={updateFormData} hasError={violationFields[FieldNames.MAIL]} name={FieldNames.MAIL} />
              </HalfWidthCell>
            </GridContainer>
            <GridContainer>
              <HalfWidthCell expandForMobile={true}>
                <InputField placeholder={t('order:orderForm.placeholderFirstName')} errorMessage="" value={formData[FieldNames.FIRST_NAME]} onChange={updateFormData} hasError={violationFields[FieldNames.FIRST_NAME]} name={FieldNames.FIRST_NAME} />
              </HalfWidthCell>
              <HalfWidthCell expandForMobile={true}>
                <InputField placeholder={t('order:orderForm.placeholderLastName')} errorMessage="" value={formData[FieldNames.LAST_NAME]} onChange={updateFormData} hasError={violationFields[FieldNames.LAST_NAME]} name={FieldNames.LAST_NAME} />
              </HalfWidthCell>
            </GridContainer>
            <GridContainer>
              <HalfWidthCell expandForMobile={true}>
                <InputField placeholder={t('order:orderForm.placeholderStreet')} errorMessage="" value={formData[FieldNames.STREET]} onChange={updateFormData} hasError={violationFields[FieldNames.STREET]} name={FieldNames.STREET} />
              </HalfWidthCell>
              <HalfWidthCell expandForMobile={true}>
                <InputField placeholder={t('order:orderForm.placeholderStreetNumber')} errorMessage="" value={formData[FieldNames.STREET_NUMBER]} onChange={updateFormData} hasError={violationFields[FieldNames.STREET_NUMBER]} name={FieldNames.STREET_NUMBER} />
              </HalfWidthCell>
            </GridContainer>
            <GridContainer>
              <HalfWidthCell expandForMobile={true}>
                <InputField placeholder={t('order:orderForm.placeholderZipCode')} errorMessage="" value={formData[FieldNames.ZIP_CODE]} onChange={updateFormData} hasError={violationFields[FieldNames.ZIP_CODE]} name={FieldNames.ZIP_CODE} />
              </HalfWidthCell>
              <HalfWidthCell expandForMobile={true}>
                <InputField placeholder={t('order:orderForm.placeholderCity')} errorMessage="" value={formData[FieldNames.CITY]} onChange={updateFormData} hasError={violationFields[FieldNames.CITY]} name={FieldNames.CITY} />
              </HalfWidthCell>
            </GridContainer>
            <GridContainer>
              <HalfWidthCell expandForMobile={true}>
                <InputField placeholder={t('order:orderForm.placeholderAmount')} errorMessage="" value={formData[FieldNames.AMOUNT]} onChange={updateFormData} hasError={violationFields[FieldNames.AMOUNT]} name={FieldNames.AMOUNT} />
              </HalfWidthCell>
            </GridContainer>
            <FullWidthContainer>
              <div className={styles.privacySection}>
                <CheckboxField value={formData[FieldNames.PRIVACY_POLICY_ACCEPTED]} name={FieldNames.PRIVACY_POLICY_ACCEPTED} onChange={updateFormData} hasError={violationFields[FieldNames.PRIVACY_POLICY_ACCEPTED]}>
                  <Text text="order:orderForm.labelDataPrivacy" />
                </CheckboxField>
              </div>
            </FullWidthContainer>
            <FullWidthContainer>
              <div className={styles.requiredFieldsLabel}>
                <Text text="order:orderForm.labelRequiredFields" />
              </div>
              {formHasErrors && (
                <div className={styles.errorMessage}>
                  <Text text="order:orderForm.errorMessage" />
                </div>
              )}
              <div className={styles.submitButton}>
                <Button title={t('order:orderForm.titleSubmitButton')} uppercase={true} onSubmit={submitForm}>
                  <Text text="order:orderForm.labelSubmitButton" />
                </Button>
              </div>
            </FullWidthContainer>
          </>
        )}
    </div>
  );
};

export default OrderForm;